// regex Javascript Thousand Separator
export const thousandSeparator = (str: string) => {
    const [integerPart, decimalPart] = str.split('.')
    if (!decimalPart) {
        return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    return [integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ','), decimalPart].join('.')
}
export const thirdDecimalPlace = (num: number | string, type?: string) => {
    if (typeof num === 'string' || isNaN(num)) return type === 'input' ? '' : '-'
    return num.toFixed(3)
}

export const safeNaN = (num: number) => {
    return isNaN(num) ? 0 : num
}
