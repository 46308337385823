import Space from '@/components/atoms/Space'
import SelectorGroup from '@/components/molecules/SelectorGroup'
import { withLayout } from '@/components/template/Layout'
import SalesTable from '@/components/template/Sales/SalesTable'
import theme from '@/theme'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core'
import React from 'react'

const Sales = () => {
    const { expandedMenu } = useStore()
    const useStyle = makeStyles({
        main: {
            marginBottom: 50,
            position: 'relative',
            maxWidth: theme.contentContainer.width,
        },
        header: {
            position: 'fixed',
            zIndex: 1,
            top: 73,
            width: `calc(100% - ${expandedMenu ? 300 : 120}px)`,
            transition: 'width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
        selectGroup: {},
        content: {
            paddingTop: 78,
        },
    })
    const classes = useStyle()

    return (
        <main className={classes.main}>
            <title>データ入力｜ScopeX</title>
            <div>
                <div className={classes.header}>
                    <Space />
                    <div className={classes.selectGroup}>
                        <SelectorGroup />
                    </div>
                    <Space height={15} />
                </div>
            </div>
            <div style={{ maxWidth: theme.contentContainer.width }} className={classes.content}>
                <SalesTable />
            </div>
        </main>
    )
}
export default withLayout(Sales)
