import EditIcon from '@/components/atoms/Icon/svg/EditIcon'
import EditModal from '@/components/organisms/Sales/EditModal'
import { withLayout } from '@/components/template/Layout'
import { siteSalesApi } from '@/ghgApi'
import { SiteSales } from '@/openapi'
import theme from '@/theme'
import { thousandSeparator } from '@/utils/numHelper'
import useStore from '@/zustand/sotre'
import { IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

const listHeader = [
    { id: 1, text: '年度' },
    { id: 2, text: '売上高 (百万円)' },
    { id: 3, text: '生産量 (kg)' },
    { id: 4, text: '編集' },
]

const useStyte = makeStyles({
    tableContainer: {
        backgroundColor: theme.colors.white,
        borderTop: `1px solid ${theme.colors.borderColor}`,
        borderLeft: `1px solid ${theme.colors.borderColor}`,
        borderRight: `1px solid ${theme.colors.borderColor}`,
    },
    tableRowHead: {
        '& > th': {
            fontWeight: 600,
        },
        backgroundColor: theme.colors.lightGray,
    },
    tableRowBody: {
        '&:nth-child(even)': {
            backgroundColor: theme.colors.lightGray,
        },
    },
    tableCell: {
        fontSize: 14,
        minWidth: 200,
        textAlign: 'center',
        '&:not(:last-child)': {
            borderRight: `1px dashed ${theme.colors.borderColor}`,
        },
    },
    forceAlignLeft: {
        textAlign: 'left!important' as "left",
    },
})

function SalesTable() {
    const classes = useStyte()
    const { analysisState, fetchStoreData, storeState } = useStore()
    const [siteSalesList, setSiteSalesList] = useState<Array<SiteSales>>([])
    const [editModalVisibilities, setEditModalVisibilities] = useState<{ [key: number]: boolean }>({})

    useEffect(() => {
        if (!storeState.selectedSite) {
            console.info("fetch store-data.")
            fetchStoreData()
            if (!storeState.selectedSite) {
                console.warn("selectedSite is empty.")
                setSiteSalesList([])
                return
            }
        }
        fetchSiteSales(storeState.selectedSite.id)
    }, [storeState.selectedSite])

    const fetchSiteSales = async (siteId: number) => {
        try {
            const res = await siteSalesApi.getSiteSales(siteId)
            const { salesList } = res.data
            if (salesList) {
                setSiteSalesList(formatSiteSalesList(siteId, salesList))
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const formatSiteSalesList = (siteId: number, siteSalesList: SiteSales[]) => {
        const results = new Array<SiteSales>()
        analysisState.dateSelector.options.years.forEach((processYear) => {
            const siteSales = siteSalesList.find((siteSales) => siteSales.year === processYear)
            if (siteSales) {
                results.push(siteSales)
            } else {
                results.push({ siteId: siteId, year: processYear })
            }
        })
        return results
    }

    const handleClickEdit = (year: number) => {
        setEditModalVisibilities((prevStates) => ({
            ...prevStates,
            [year]: true,
        }))
    }

    const handleSave = async (_: number) => {
        if (!storeState.selectedSite) {
            console.warn("selectedSite is empty.")
            return
        }
        try {
            await fetchSiteSales(storeState.selectedSite.id)
        } catch (err) {
            console.log(err)
        }
    }

    const handleClose = (year: number) => {
        setEditModalVisibilities((prevStates) => ({
            ...prevStates,
            [year]: false,
        }))
    }

    return (
        <TableContainer>
            <Table className={classes.tableContainer}>
                <TableHead>
                    <TableRow className={classes.tableRowHead}>
                        {listHeader.map((item) => (
                            <TableCell key={item.id} className={classes.tableCell}>
                                {item.text}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {siteSalesList.map((siteSales) => {
                        return (
                            <TableRow className={classes.tableRowBody} key={`${siteSales.siteId}_${siteSales.year}`}>
                                <TableCell className={classes.tableCell}>{siteSales.year}</TableCell>
                                <TableCell className={`${classes.tableCell} ${siteSales.sales || siteSales.sales === 0 ? classes.forceAlignLeft : ''}`}>{thousandSeparator(siteSales.sales?.toString() ?? "-")}</TableCell>
                                <TableCell className={`${classes.tableCell} ${siteSales.productionVolume || siteSales.productionVolume === 0 ? classes.forceAlignLeft : ''}`}>{thousandSeparator(siteSales.productionVolume?.toString() ?? "-")}</TableCell>
                                <TableCell className={`${classes.tableCell}`}>
                                    <IconButton
                                        style={{ padding: '0' }}
                                        onClick={() => handleClickEdit(siteSales.year)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <EditModal
                                        siteSales={{ ...siteSales }}
                                        open={editModalVisibilities[siteSales.year] || false}
                                        onSave={() => handleSave(siteSales.year)}
                                        onClose={() => handleClose(siteSales.year)}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SalesTable
