import theme from '@/theme'
import { Button, ButtonProps, makeStyles } from '@material-ui/core'
import React from 'react'

function OutlineButton({
    fullSize = false,
    classes,
    ...props
}: ButtonProps & { fullSize?: boolean; component?: string }) {
    const styles = {
        root: {
            width: fullSize ? '100%' : 'auto',
            background: theme.colors.white,
            border: `1px solid ${theme.colors.primary}`,
            color: theme.colors.primary,
            fontFamily: theme.typography.fontFamily,
            fontSize: 20,
            borderRadius: '8px',
            height: 48,
            '&:hover': {
                background: theme.colors.white,
            },
        },
    }
    const defaultClasses = makeStyles(styles)()

    return (
        <Button classes={{ ...defaultClasses, ...classes }} {...props}>
            {props.children}
        </Button>
    )
}

export default OutlineButton
