import OutlineButton from '@/components/atoms/Button/OutlineButton'
import PrimaryButton from '@/components/atoms/Button/PrimaryButton'
import CustomModal from '@/components/atoms/CustomModal'
import InputField from '@/components/atoms/InputField'
import { ERROR } from '@/constant/errors'
import { siteSalesApi } from '@/ghgApi'
import { SiteSales } from '@/openapi'
import useStore from '@/zustand/sotre'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import 'react-tooltip/dist/react-tooltip.css'

const useStyles = makeStyles({
    inputArea: {
        color: '#222222',
        textAlign: 'left',
        padding: '0 200px 0',
    },
    item: {
        marginTop: '20px',
    },
    itemName: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    itemValue: {
        fontSize: '16px',
        paddingLeft: '12px',
        marginTop: '10px',
    },
    button: {
        fontSize: '14px !important',
        padding: '11px 24px',
        minWidth: '175px',
    },
    formBtnGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: 30,
        marginTop: '32px',
    },
    completedMessageArea: {
        fontSize: '14px',
        color: '#222222',
        textAlign: 'center',
        marginTop: '25px',
    },
    completedResultArea: {
        background: '#F7F7F7 0% 0% no-repeat padding-box',
        fontSize: '14px',
        marginTop: '25px',
        padding: '32px 56px',
    },
    completedItemRow: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        maxWidth: '600px',
        '&:not(:first-child)': {
            'margin-top': '32px',
        },
    },
    completedItemName: {
        fontWeight: 'bold',
        textAlign: 'left',
        width: '20%',
    },
    completedItemValue: {
        textAlign: 'left',
        width: '70%',
    },
})

export default function EditModal(
    props: { 
        siteSales: SiteSales,
        open: boolean,
        onSave: () => void,
        onClose: () => void,
     }
) {
    const SALES_MAX_LENGTH: number = 15
    const PRODUCTION_VOLUME_MAX_LENGTH: number = 15
    const classes = useStyles()
    const [completed, setCompleted] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [sales, setSales] = useState<number | undefined>()
    const [productionVolume, setProductionVolume] = useState<number | undefined>()
    const [salesErrorMessage, setSalesErrorMessage] = useState<string>('')
    const [productionVolumeErrorMessage, setProductionVolumeErrorMessage] = useState<string>('')
    const { setMessage, storeState } = useStore()

    useEffect(() => {
        if (props.open) {
            setSales(props.siteSales.sales)
            setProductionVolume(props.siteSales.productionVolume)
        }
    }, [props.open])

    const handleChangeSales = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const sales = e.target.value
        setSales(Number.isNaN(parseInt(sales)) ? undefined : parseInt(sales))
        validateSales(sales)
    }

    const handleChangeProductionVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const productionVolume = e.target.value
        setProductionVolume(Number.isNaN(parseInt(productionVolume)) ? undefined : parseInt(productionVolume))
        validateProductionVolume(productionVolume)
    }

    const validateSales = (sales: string) => {
        if (!sales) {
            setSalesErrorMessage('')
            return
        }
        if (!/^[0-9]*$/.test(sales)) {
            setSalesErrorMessage(ERROR.NON_NUMERIC_CHARACTERS)
            return
        }
        if (sales.length > SALES_MAX_LENGTH) {
            setSalesErrorMessage(`最大${SALES_MAX_LENGTH}桁です。`)
            return
        }
        setSalesErrorMessage('')
    }

    const validateProductionVolume = (productionVolume: string) => {
        if (!productionVolume) {
            setProductionVolumeErrorMessage('')
            return
        }
        if (!/^[0-9]*$/.test(productionVolume)) {
            setProductionVolumeErrorMessage(ERROR.NON_NUMERIC_CHARACTERS)
            return
        }
        if (productionVolume.length > PRODUCTION_VOLUME_MAX_LENGTH) {
            setProductionVolumeErrorMessage(`最大${PRODUCTION_VOLUME_MAX_LENGTH}桁です。`)
            return
        }
        setProductionVolumeErrorMessage('')
    }

    const handleSave = async () => {
        if (processing) {
            return
        }
        setProcessing(true)
        if ((props.siteSales.sales === sales) && (props.siteSales.productionVolume === productionVolume)) {
            setMessage({ message: '項目を一つ以上入力・編集してください。', type: 'error' })
            setProcessing(false)
            return
        }
        try {
            await siteSalesApi.putSiteSales(
                props.siteSales.siteId,
                props.siteSales.year,
                { sales: sales, productionVolume: productionVolume },
            )
        } catch (err) {
            console.log(err)
            setMessage({ message: '企業数値の登録に失敗しました。時間を置いてから再度お試しください。', type: 'error' })
            clearState()
            return
        }
        setProcessing(false)
        setCompleted(true)
    }

    const handleClose = () => {
        if (completed) {
            props.onSave()
        }
        clearState()
        props.onClose()
    }

    const clearState = () => {
        setProcessing(false)
        setCompleted(false)
        setSales(undefined)
        setProductionVolume(undefined)
        setSalesErrorMessage('')
        setProductionVolumeErrorMessage('')
    }

    const renderInputView = () => {
        const view = () => {
            return (
                <>
                    <div className={classes.inputArea}>
                        <div className={classes.item}>
                            <div className={classes.itemName}>年度</div>
                            <div className={classes.itemValue}>{props.siteSales.year}年度</div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemName}>売上高 (百万円)</div>
                            <div className={classes.itemValue}>
                                <InputField
                                    name="sales"
                                    defaultValue={props.siteSales.sales}
                                    onChange={handleChangeSales}
                                    error={!!salesErrorMessage}
                                    helperText={salesErrorMessage}
                                />
                            </div>
                        </div>
                        <div className={classes.item}>
                            <div className={classes.itemName}>生産量 (kg)</div>
                            <div className={classes.itemValue}>
                                <InputField
                                    name="productionVolume"
                                    defaultValue={props.siteSales.productionVolume}
                                    onChange={handleChangeProductionVolume}
                                    error={!!productionVolumeErrorMessage}
                                    helperText={productionVolumeErrorMessage}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.formBtnGroup}>
                        <OutlineButton
                            className={classes.button}
                            onClick={handleClose}>
                            キャンセル
                        </OutlineButton>
                        <PrimaryButton
                            className={classes.button}
                            disabled={!!salesErrorMessage || !!productionVolumeErrorMessage}
                            onClick={handleSave}>
                            登録する
                        </PrimaryButton>
                    </div>
                </>
            )
        }
        return <div>{ view() }</div>
    }

    const renderCompletedView = () => {
        const view = () => {
            return (
                <>
                    <div className={classes.completedMessageArea}>
                        <div>企業数値の登録が完了しました。</div>
                    </div>
                    <div className={classes.completedResultArea}>
                        <div className={classes.completedItemRow}>
                            <div className={classes.completedItemName}>年度</div>
                            <div className={classes.completedItemValue}>{ props.siteSales.year }年度</div>
                        </div>
                        <div className={classes.completedItemRow}>
                            <div className={classes.completedItemName}>売上高 (百万円)</div>
                            <div className={classes.completedItemValue}>{(sales === undefined) ? '' : sales}</div>
                        </div>
                        <div className={classes.completedItemRow}>
                            <div className={classes.completedItemName}>生産量 (kg)</div>
                            <div className={classes.completedItemValue}>{(productionVolume === undefined) ? '' : productionVolume}</div>
                        </div>
                    </div>
                    <div className={classes.formBtnGroup}>
                        <OutlineButton
                            className={classes.button}
                            onClick={handleClose}>
                            閉じる
                        </OutlineButton>
                    </div>
                </>
            )
        }
        return <div>{view()}</div>
    }

    return(
        <CustomModal
            title={`その他の企業数値登録 ＞ ${storeState.selectedSite?.name ?? ''}`}
            open={props.open}
            onClose={handleClose}
            width={'960px'}
            minHeight={'310px'}>
            {completed ? renderCompletedView() : renderInputView() }
        </CustomModal>
    )
}
